/* @flow */
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { push } from 'react-router-redux';
import l from 'core/utils/localization';
import BaseTheme, { Provider as BaseThemeProvider } from 'core/contexts/theme';
import Themes from 'core/contexts/theme/Themes';
import { supportsDarkMode } from '@kingstinct/react-native-dark-mode';
import PremiumInfo from 'core/components/PremiumInfo';

import { Provider as MaterialThemeProvider } from '../contexts/theme-material';


const styles = {
  item: { width: '100%', display: 'flex', flexDirection: 'row' },
  brightnessIcon: { marginTop: 7, marginRight: 10 },
  alignCenter: { alignSelf: 'center' },
  paper: {
    height: 200,
    cursor: 'pointer',
    // outlineOffset: 5,
    outlineWidth: 1,
    transition: 'all 0.2s ease-in',
  },
  wrapper: {
    flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-around', padding: '1%',
  },
  themeContainer: {
    width: '49%', minWidth: 200, padding: '1%', paddingBottom: '10%',
  },
  container: { height: '100%' },
  toolbar: { },
  darkModeWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' },
};

const ThemeSelector = () => {
  const dispatch = useDispatch(),
        {
          theme, setTheme, autoDarkMode, toggleAutoDarkMode,
        } = useContext(BaseTheme),
        onClick = () => dispatch(push('/profile'));

  return (
    <div style={styles.container}>
      <Toolbar style={styles.toolbar}>
        <Button
          onClick={onClick}
          variant='text'
          style={{ marginRight: 20 }}
        >
          <Typography style={{ color: theme.colors.text }}>{`< ${l.profile}`}</Typography>
        </Button>
        <Typography variant='h6' color='textPrimary'>{ l.themeSelector }</Typography>
      </Toolbar>
      <div style={{ display: 'flex', justifyContent: 'center' }}><PremiumInfo /></div>
      { supportsDarkMode ? (
        <div style={styles.darkModeWrapper}>
          <BrightnessMediumIcon
            style={{
              ...styles.brightnessIcon,
              color: theme.colors.text,
            }}
          />
          <Typography color='textPrimary' style={{ marginTop: 7 }}>{ l.autoDarkMode }</Typography>
          <Switch
            onChange={toggleAutoDarkMode}
            checked={autoDarkMode}
          />
        </div>
      ) : null }
      <div style={styles.wrapper}>
        { Themes.map(t => (
          <div style={styles.themeContainer} key={t.key}>
            <BaseThemeProvider value={{ theme }}>
              <MaterialThemeProvider>
                <Paper
                  style={{
                    ...styles.paper,
                    backgroundColor: t.colors.background,
                    outlineStyle: t.key === theme.key ? 'solid' : 'none',
                    transform: t.key === theme.key ? 'scale(1)' : 'scale(0.95)',
                    outlineColor: t.colors.text,
                  }}
                  elevation={3}
                  // onMouseEnter={() => setTheme(t)}
                  onClick={() => setTheme(t)}
                >
                  <div style={{ pointerEvents: 'none' }}>
                    <div style={{ width: '100%', backgroundColor: t.colors.primary }}>
                      <Typography
                        style={{ color: t.colors.primaryText, padding: 5 }}
                      >
                        {t.title}
                      </Typography>
                    </div>
                    <div style={styles.item}>
                      <Checkbox />
                      <Typography style={styles.alignCenter}>Item 1</Typography>
                    </div>
                    <div style={styles.item}>
                      <Checkbox checked />
                      <Typography style={styles.alignCenter}>Item 2</Typography>
                    </div>
                  </div>
                </Paper>
              </MaterialThemeProvider>
            </BaseThemeProvider>
          </div>
        )) }
      </div>
    </div>
  );
};

export default ThemeSelector;
