/* @flow */

import React, { PureComponent } from 'react';
import {
  Animated,
  View,
  Text,
  Easing,
  Dimensions,
} from 'react-native';
import { sample } from 'lodash/fp';
import Typography from '@material-ui/core/Typography';
import l from 'core/utils/localization';
import { withTheme } from 'core/contexts/theme';


const styles = {
  congratulationText: {
    fontSize: 25,
    backgroundColor: 'transparent',
    alignSelf: 'center',
    textAlign: 'center',
    margin: 10,
  },
  backgroundImage: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    flex: 1,
  },
  congratulationTextContainer: {
    flex: 1,
    padding: 20,
    marginTop: 70,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
  },
  emoji: {
    fontSize: 40, textAlign: 'center', alignSelf: 'center', margin: 10,
  },
};

type State = {
  backgroundImageOpacity: Animated.Value,
  width: number,
  height: number,
  emoji: string
};

type Props = {
  showCongratulationText: boolean,
  theme: ThemeContextData
};

const getEmoji = () => sample([
  '👏👏',
  '🤜🤛',
  '🎉🎉🎉',
  '🍻',
  '☕️',
  '🍾',
  '😄',
  '💃🕺',
  '👌',
  '☀️',
  '😎',
  '💰',
  '🏃',
  '🏃🏿',
  '🏆',
  '🏅',
  '🎖',
  '✨',
  '⭐️⭐️⭐️',
  '😅',
  '👍',
  '👍🏾',
  '🥇',
]);

class ListBackgroundImage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { width, height } = Dimensions.get('screen');

    this.state = {
      width,
      height,
      emoji: getEmoji(),
      backgroundImageOpacity: new Animated.Value(0.3),
    };
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    const { showCongratulationText } = this.props;

    if (props.showCongratulationText !== showCongratulationText) {
      this.updateImageOpacity(props);
      this.setState({ emoji: getEmoji() });
    }
  }

  updateImageOpacity = (props: Props = this.props) => {
    const { backgroundImageOpacity } = this.state;

    if (props.showCongratulationText) {
      Animated.timing(backgroundImageOpacity, {
        toValue: 0.5,
        duration: 500,
        // $FlowFixMe
        easing: Easing.easeOut,
      }).start();
    } else {
      Animated.timing(backgroundImageOpacity, {
        toValue: 0.3,
        duration: 500,
        // $FlowFixMe
        easing: Easing.easeOut,
      }).start();
    }
  }

  onDimensionsUpdate = ({
    screen,
  }: {
    screen: { width: number, height: number }
  }) => {
    const { width, height } = screen;
    this.setState({
      height,
      width,
    });
  }

  fadeInImage = () => {
    this.updateImageOpacity();
  }

  render() {
    const { showCongratulationText, theme } = this.props,
          { backgroundImageOpacity, emoji } = this.state;

    return (
      <View style={styles.container}>
        <Animated.Image
          style={{
            ...styles.backgroundImage,
            opacity: backgroundImageOpacity,
          }}
          resizeMode='cover'
          onLoad={this.fadeInImage}
          source={theme.backgroundImage} // eslint-disable-line global-require
        />
        {showCongratulationText
          ? (
            <View style={styles.congratulationTextContainer}>
              <Typography variant='h5' style={{ ...styles.congratulationText, color: theme.colors.text }}>
                {l.noTasksLeft}
              </Typography>
              <Text style={styles.emoji}>
                {emoji}
              </Text>
            </View>
          )
          : null}
      </View>
    );
  }
}

export default withTheme(ListBackgroundImage);
