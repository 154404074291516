/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  TouchableWithoutFeedback,
  TouchableOpacity,
  Text,
  View,
} from 'react-native';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import FontAwesomeIcons from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import dayjs from 'dayjs';
import { find } from 'lodash/fp';
import l from 'core/utils/localization';
import { postponeOptionsSelector } from 'core/modules/postpone/selectors';

import DatePicker from './DatePicker';


type Props = {
  postponeOptions: Array<Object>,
  onSelectPostponed: (index: number) => void,
  onClosed: () => void,
  currentDate: ?Date,
  showDialog?: boolean,
};

type State = {
  date: Date,
  showConfigureExactTime: boolean
};

const styles = {
  confirmButton: { backgroundColor: 'white', padding: 20 },
  cancelTouchableStyle: { position: 'absolute', left: 10 },
  bottomContainer: {

  },
  itemTouchable: {
    height: 135,
    width: 135,
  },
  itemIcon: { textAlign: 'center' },
  itemIconContainer: { flex: 3, justifyContent: 'center' },
  touchable: {
  },
  itemContainer: {
    flex: 1,
    borderRadius: 10,
    margin: 10,
    paddingRight: 5,
    paddingLeft: 5,
    boxShadow: '2px 1px 10px rgb(190,190,190)',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
  },
  itemTextContainer: {
    flex: 2,
    justifyContent: 'center',
  },
  itemText: {
    textAlign: 'center',
    fontSize: 16,
  },
  scrollViewContainer: {
    alignSelf: 'center',
  },
  postponeOptionsContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: 500,
    paddingTop: 20,
  },
  scrollViewContentContainer: {
    alignSelf: 'center',
  },
  header: {
    textAlign: 'center', margin: 10, fontSize: 19, paddingTop: 10,
  },
  flexOne: {
    flex: 1,
  },
};

const IconSets = {
  FontAwesomeIcons,
  MaterialCommunityIcons,
};

const DynamicIcon = ({ iconSet, ...props }) => {
  const IconComponent = iconSet ? IconSets[iconSet] : IconSets.FontAwesomeIcons;

  return <IconComponent {...props} />;
};

const getDefaultDate = ({ currentDate }: Props) => currentDate && currentDate > new Date()
  ? currentDate
  : dayjs().startOf('hour').add(3, 'hours').toDate();

class PostponeModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      date: getDefaultDate(props),
      showConfigureExactTime: false,
    };
  }

  onSelectPostponed = (indexOrDate: number | Date) => {
    const { postponeOptions, onSelectPostponed } = this.props;

    if (indexOrDate === 0) {
      onSelectPostponed(indexOrDate);
    } else {
      const postponedUntil = indexOrDate instanceof Date
        ? indexOrDate
        : find(
          o => o.text === postponeOptions[indexOrDate].text,
          postponeOptions,
        )
          .resolve()
          .toDate();
      onSelectPostponed(postponedUntil);
    }
  }

  setDatepickerToIndex = (index: number) => {
    const { postponeOptions, onSelectPostponed } = this.props,
          { showConfigureExactTime } = this.state;

    if (index === 0) {
      // cancel
      onSelectPostponed(0);
    } else {
      const date = postponeOptions[index].resolve().toDate();
      this.setState({ date });
      if (!showConfigureExactTime) {
        this.setState({ showConfigureExactTime: true });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { currentDate } = this.props;

    if (newProps.currentDate !== currentDate) {
      this.setState({
        date: getDefaultDate(newProps),
      });
    }
  }

  close = () => {
    const { onClosed } = this.props;

    onClosed();
  }

  onUpdateDate = (date: Date) => {
    this.setState({ date });
  }

  props: Props;

  state: State;

  renderItem = ({ text, icon, iconSet }: Object, index: number) => {
    const color = index === 0 ? 'white' : 'black',
          backgroundColor = index === 0
            ? 'rgb(100,100,100)'
            : 'white';

    return index === 0 ? null : (
      <TouchableOpacity
        key={text}
        onPress={() => this.onSelectPostponed(index)}
        onLongPress={() => this.setDatepickerToIndex(index)}
        style={styles.itemTouchable}
      >
        <View style={{ ...styles.itemContainer, backgroundColor }}>
          <View style={styles.itemIconContainer}>
            <DynamicIcon
              name={icon}
              size={40}
              iconSet={iconSet}
              style={styles.itemIcon}
              color={color}
            />
          </View>
          <View style={styles.itemTextContainer}>
            <Text style={{ ...styles.itemText, color }}>{text}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  toggleShowExactTime = () => {
    const { showConfigureExactTime } = this.state;
    if (showConfigureExactTime) {
      this.setState({ showConfigureExactTime: false });
    } else {
      this.setState({ showConfigureExactTime: true });
    }
  }

  render() {
    const { postponeOptions, showDialog } = this.props,
          { date, showConfigureExactTime } = this.state;

    const actions = showConfigureExactTime
      ? [
        <Button
          variant='text'
          onClick={this.toggleShowExactTime}
          key='1'
        >
          {l.cancel}
        </Button>, <Button
          variant='contained'
          color='primary'
          key='2'
          onClick={() => this.onSelectPostponed(date)}
        >
          {l.confirmExactTime}
        </Button>,
      ]
      : [
        <Button
          variant='text'
          key='1'
          onClick={this.close}
        >
          {l.cancel}
        </Button>,
        <Button
          variant='contained'
          key='2'
          onClick={this.toggleShowExactTime}
          color='primary'
        >
          {l.custom}
        </Button>,
      ];


    return (
      <Dialog
        open={showDialog}
        onClose={this.close}
        PaperProps={{
          style: {
          },
        }}
      >
        <TouchableWithoutFeedback>
          <View style={{ ...styles.touchable, overflow: 'auto' }}>
            { !showConfigureExactTime ? (
              <TouchableWithoutFeedback
                style={styles.flexOne}
              >
                <View style={styles.postponeOptionsContainer}>
                  {postponeOptions.map(this.renderItem)}
                </View>
              </TouchableWithoutFeedback>
            ) : (
              <View style={styles.bottomContainer} pointerEvents='box-none'>
                <DatePicker
                  onUpdateDate={this.onUpdateDate}
                  currentDate={date}
                  minimumDate={new Date()}
                />
              </View>
            )}
            <div style={{
              flexDirection: 'row', justifyContent: 'space-between', display: 'flex', padding: 20,
            }}
            >
              { actions }
            </div>

          </View>
        </TouchableWithoutFeedback>
      </Dialog>
    );
  }
}

PostponeModal.defaultProps = {
  showDialog: false,
};

export default connect(
  state => ({
    postponeOptions: postponeOptionsSelector(state),
  }),
)(PostponeModal);
